import { render, staticRenderFns } from "./mobile-case-5GF.vue?vue&type=template&id=ab567f20&scoped=true"
import script from "./mobile-case-5GF.vue?vue&type=script&lang=js"
export * from "./mobile-case-5GF.vue?vue&type=script&lang=js"
import style0 from "./mobile-case-5GF.vue?vue&type=style&index=0&id=ab567f20&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab567f20",
  null
  
)

export default component.exports