<template>
    <div>
        <headTitle/>
        <img class="mobile-example-headImg" src="../../assets/image/mobile/03-25GF整合数据分析系统.png"/>
        <div class="mobile-head-explain">
            <div class="mobile-head-explain-title">5GF整合数据分析系统</div>
            <div class="mobile-head-explain-content">全球某知名日用消费品公司</div>
        </div>
        <div class="description">
            <div class="title1">项目介绍</div>
            <div v-if="!showDescription" class="title2" @click="(showDescription = !showDescription)">展开<i class="el-icon-arrow-down"></i></div>
            <div v-if="showDescription" class="title2" @click="(showDescription = !showDescription)">收起<i class="el-icon-arrow-up"></i></div>
        </div>
        <div style="margin-top: 2rem;">
            <el-collapse-transition>
                <div v-if="showDescription">
                    <div class="description-box">作为世界上最大的日用消费品公司之一，客户公司覆盖14个品类的400个品牌，畅销于全球170多个国家和地
                区。在各组织内对于各类报表处在大量的需求，可是当时财务部门仍然存在大量纸质或者Excel报表，以至于需要人工录入及核查，影响了报表的时效
                性和准确性。所以靠人工对企业经营数据进行统计分析，已经无法满足公司的精细化管理要求。</div>
                </div>
            </el-collapse-transition>
        </div>
        <div class="mobile-head-catalogue" style="margin-top:5.7rem">
            <div :class="{'title1':true,'ischecked':checkIndex==1} " @click="(checkIndex=1)"><span>业务痛点</span></div>
            <div :class="{'title1':true,'ischecked':checkIndex==2} " @click="(checkIndex=2)"><span>实施方案</span></div>
            <div :class="{'title1':true,'ischecked':checkIndex==3} " @click="(checkIndex=3)"><span>实施收益</span></div>
        </div>
        <div v-if="(checkIndex==1)" class="mobile-content-box1">
            <div class="ware-box1">
                <div class="title1">
                    各部门有大量纸质、Excel报表需求，存在大量<br/>
                        人工抄写、录入及计算，时效性和准确性较差
                </div>
            </div>
            <div class="ware-box1" style="margin-bottom:5rem">
                <div class="title1">
                    靠人工对企业经营数据进行统计分析，<br/>已经无法满足公司的精细化管理要求
                </div>
            </div>
        </div>
        <div v-else-if="(checkIndex==2)" class="mobile-content-box1">
            <div class="purchase-box">
                    <div class="card">
                        <img class="logo" src="../../assets/image/selected/03-2-1更好的体验.svg"/>
                        <div>
                            <div class="title2">使最终用户拥有更好的体验 </div>
                        </div>
                    </div>
                    <div class="card">
                        <img class="logo" src="../../assets/image/selected/03-2-15GF报告.svg"/>
                        <div>
                            <div class="title2">建立自动流程以生成5GF报告数据</div>
                        </div>
                    </div>
                    <div class="card">
                        <img class="logo" src="../../assets/image/selected/03-2-1数据分析.svg"/>
                        <div>
                            <div class="title2">节省分析师的时间，使其专注于数据分析</div>
                        </div>
                    </div>
                </div>
        </div>
        <div v-else-if="(checkIndex==3)" class="mobile-content-box1">
            <div class="purchase-box">
                <div class="card2">
                    <img class="logo2" src="../../assets/image/selected/03-2-1数据源.svg"/>
                    <div class="contain2">
                        <div style="display:flex;margin:auto">
                            <div class="title2-1">30</div>
                            <div class="title2-2">个数据源</div> 
                        </div>

                    </div>
                </div>
                <div class="card2">
                    <img class="logo2" src="../../assets/image/selected/03-2-1KPI.svg"/>
                    <div class="contain2">
                        <div style="display:flex;margin:auto">
                            <div class="title2-1">20+</div>
                            <div class="title2-2">核心KPI</div> 
                        </div>

                    </div>
                </div><div class="card2">
                    <img class="logo2" src="../../assets/image/selected/03-2-1人工成本.svg"/>
                    <div class="contain2">
                        <div style="display:flex;margin:auto">
                            <div class="title2-2">高度节约人工成本</div> 
                        </div>

                    </div>
                </div><div class="card2">
                    <img class="logo2" src="../../assets/image/selected/03-2-1缩短报表时间.svg"/>
                    <div class="contain2">
                        <div style="display:flex;margin:auto">
                            <div class="title2-2">缩短报表制作时间</div> 
                        </div>

                    </div>
                </div>
                <div class="card2">
                    <img class="logo2" src="../../assets/image/selected/03-2-1自动处理.svg"/>
                    <div class="contain2">
                        <div style="display:flex;margin:auto">
                            <div class="title2-2">自动化处理数据</div> 
                        </div>

                    </div>
                </div>
                <div style="width: 20rem;height: 21rem;">
                </div>
            </div>
                
        </div>
        <mobileFoot/>
    </div>
</template>

<script>
import headTitle from '../../components/mobile/head-title.vue'
import mobileFoot from '../../components/mobile/mobile-foot.vue'
import mobileCarousel from '../../components/mobile/mobile-carousel.vue'
import Swiper from "swiper";
export default {
    name: 'BizfocusIndexMobileIndex',
    components:{headTitle,mobileCarousel,mobileFoot},
    data() {
        return {
            checkIndex: 1,
            showDescription:true,
            box2ImageList:[
                {
                    index:0,
                    urlImg:require("../../assets/image/index/01-15GF整合数据分析系统.png"),
                    title:'5GF整合数据分析系统',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-5GF'
                },
                {
                    index:1,
                    urlImg:require("../../assets/image/index/01-1数字化产线.png"),
                    title:'数字化产线', //右转是0
                    content:'打造透明、高效、稳定的数字化生产管理平台',
                    route:'/selected-line'
                },
                {
                    index:2,
                    urlImg:require("../../assets/image/index/01-1市场渗透率分析.png"),
                    title:'市场渗透率分析',
                    content:'全球某化妆品巨头',
                    route:'/selected-marykay'
                },
                {
                    index:3,
                    urlImg:require("../../assets/image/index/01-1智能仓储.png"),
                    title:'智能仓储',
                    content:'布局原料赋码，助力品质生产',
                    route:'/selected-warehousing'
                },
                {
                    index:4,
                    urlImg:require("../../assets/image/index/01-1Dashboard数据实施.png"),
                    title:'Dashboard数据实施',
                    content:'全球某知名乳制品公司',
                    route:'/selected-dashboard'
                },
                {
                    index:5,
                    urlImg:require("../../assets/image/index/01-1商情诊断管理分析.png"),
                    title:'商情诊断管理分析',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-business'
                },
                {
                    index:6,
                    urlImg:require("../../assets/image/index/01-1iDAS整合数据分析系统.png"),
                    title:'iDAS整合数据分析系统', //左转是0
                    content:'韩国某知名化妆品集团',
                    route:'/selected-iDAS'
                },

            ],
        };
    },

    mounted() {
    },

    methods: {
        jumpMobileRoute(route){
            this.$router.push(route)
            console.log(route)
        }
    },
};
</script>

<style lang="less" scoped>
.description{
    display: flex;
    width: 66.5rem;
    margin: auto;
    margin-top: 6rem;
    .title1{
        font-size: 2.8rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #11A84F;
        line-height: 4.2rem;
    }
    .title2{
        font-size: 2.8rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #11A84F;
        line-height: 4.2rem;
        margin-left: 70%;
    }
}
.description-box{
    max-width: 66.5rem;
    margin: auto;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 5rem;
}
.mobile-head-catalogue{
    width: 100%;
    height: 9rem;
    display: flex;
    background: #F8F8F8;
    .title1{
        width: 20rem;
        height: 9rem;
        display: flex;
        text-align: center;
        align-items: center;
        span{
            font-size: 2.8rem;
            font-family: 'CN_Medium';
            font-weight: 500;
            color: #4F4F4F;
            line-height: 2rem;
            margin: auto;
        }
    }
    .ischecked{
        background: #EDEDED;
        border-bottom: 2px solid #11A84F;
    }
}
.mobile-content-box1{
    width: 66rem;
    margin: auto;
    margin-top: 3rem;
}
.ware-content1{
    max-width: 66rem;
    // height: 10.6rem;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 4rem;
}
.ware-title{
    font-size: 2.8rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #000000;
    line-height: 4.2rem;
}
.ware-box1{
    width: 66.8rem;
    height: 18rem;
    border: 1px solid #CCCCCC;
    margin: auto;
    margin-top: 1.3rem;
    display: flex;
    .title1{
        width: 58.8rem;
        // height: 4rem;
        font-size: 2.8rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #666666;
        line-height: 4rem; 
        margin: auto; 
        align-items: center;
    }
}
.ware-img{
    width: 66.5rem;
    height: 43.5rem;
}
.ware-img2{
    width: 66.5rem;
    height: 28rem;
}
.purchase-box{
    width: 100%;
    // height: 26.5rem;
    /*设置当前元素为flex模式*/
    display: flex;
    /*行元素默认不折行，设置为折行*/
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 5rem;
    
    .card{
        width: 52.8rem;
        height: 18rem;
        background: #FFFFFF;
        box-shadow: 0px 0px 12px 0px rgba(219,219,219,0.5);
        border-radius: 2px;
        // justify-content: center;
        margin: auto;
        margin-top: 2rem;
        display: flex;
        .logo{
            width: 4rem;
            height: 4rem;
            margin: 6rem 0 0 3rem;
        }
        .title1{
            width: 100%;
            font-size: 2.8rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #333333;
            line-height: 3rem;

            margin: 6rem 0 0 2.1rem;
        }
        .title2{
            width: 40rem;
            // height: 7.2rem;
            font-size: 2.8rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #666666;
            line-height: 4rem;

            margin: 6rem 0 0 2.1rem;
        }
    }
    .card2{
        width: 20rem;
        height: 21rem;
        background: #FFFFFF;
        margin-top: 2rem;
        box-shadow: 0px 0px 12px 0px rgba(219,219,219,0.5);
        .logo2{
            margin: 4rem 0 0 6.7rem;
            width: 6.6rem;
            height: 6.6rem;
        }
        .contain2{
            display: flex;
            text-align: center;
            width: 13.8rem;
            height: 2.6rem;
            margin: auto;
            margin-top: 3rem;
            .title2-1{
                font-size: 3rem;
                font-family: 'CN_Regular';
                font-weight: 400;
                color: #099fcc;
                line-height: 2.6rem;
                margin-top: 0.8rem;

                
            }
            .title2-2{
                height: 2.2rem;
                font-size: 2rem;
                font-family: 'CN_Regular';
                font-weight: 400;
                color: #333333;
                line-height: 2.2rem;
                margin-top: 1rem;
            }
        }
    }
}
.purchase-title{
    height: 2rem;
    font-size: 2rem;
    font-family: 'CN_Regular';
    font-weight: 500;
    color: #000000;
    line-height: 3rem;
}
.purchase-doit{
    color: #005395;
    font-size: 1rem;
    transform: scale(0.8);
    margin-right: 1.4rem;
}
.purchase-content{
    height: 7.7rem;
    font-size: 1.6rem;
    font-family: 'CN_Regular';
    font-weight: 400;
    color: #666666;
    line-height: 2.6rem;

    margin: 1.3rem 0 0 0.2rem;
}
</style>